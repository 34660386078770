<template>
    <Transition
        enter-active-class="transition-all duration-300"
        :enter-from-class="animationHiddenClasses"
        :enter-to-class="animationVisibleClasses"
        leave-active-class="transition-all duration-200"
        :leave-from-class="animationVisibleClasses"
        :leave-to-class="animationHiddenClasses"
    >
        <div
            v-if="active"
            :class="[
                'absolute flex select-none items-center justify-center transition-all',
                wrapperClasses,
            ]"
        >
            <div
                :class="[
                    'shadow-origin-md',
                    color === 'blue' && 'bg-blue-500 shadow-blue-500',
                    color === 'yellow' && 'bg-yellow-500 shadow-yellow-500',
                    color === 'black' && 'bg-black shadow-black',
                    roundingClasses,
                    dimensionClasses,
                ]"
            />
        </div>
    </Transition>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';

    const props = withDefaults(defineProps<{
        active?: boolean;
        attachedOn?: 'top' | 'bottom' | 'left' | 'right';
        color?: 'blue' | 'yellow' | 'black';
    }>(), {
        attachedOn: 'bottom',
        active: false,
        color: 'blue',
    });

    const animationHiddenClasses = computed(() => {
        return {
            top: '-translate-y-1 opacity-0',
            bottom: 'translate-y-1 opacity-0',
            left: '-translate-x-1 opacity-0',
            right: 'translate-x-1 opacity-0',
        }[props.attachedOn];
    });

    const animationVisibleClasses = computed(() => {
        return {
            top: 'translate-y-0 opacity-100',
            bottom: 'translate-y-0 opacity-100',
            left: 'translate-x-0 opacity-100',
            right: 'translate-x-0 opacity-100',
        }[props.attachedOn];
    });

    const wrapperClasses = computed(() => {
        return {
            top: 'top-0 inset-x-0',
            bottom: 'bottom-0 inset-x-0',
            left: 'left-0 inset-y-0',
            right: 'right-0 inset-y-0',
        }[props.attachedOn];
    });

    const roundingClasses = computed(() => {
        return {
            top: 'rounded-b-full',
            bottom: 'rounded-t-full',
            left: 'rounded-r-full',
            right: 'rounded-l-full',
        }[props.attachedOn];
    });

    const dimensionClasses = computed(() => {
        return {
            top: 'h-1 w-64 max-w-1/4',
            bottom: 'h-1 w-64 max-w-1/4',
            left: 'w-1 h-32 max-h-1/2',
            right: 'w-1 h-32 max-h-1/2',
        }[props.attachedOn];
    });
</script>
